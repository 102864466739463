import { RColumn, Space } from "./comps/otherCOmps";
import { Text, Title1 } from "./comps/textComps";

export default function TermCond(){
    return(
        <>
        
        <RColumn horizontalAlign="center">
        <div style={{maxWidth: "1200px"}}>
            <Space height="80px"/>
            <Title1>Terms and conditions</Title1>
            <Space height="50px"/>

            <Text fontSize="16px">Ownership: Ownership of an NFT from the Collection grants you limited rights solely related to the digital representation of the associated artwork or content. The underlying intellectual property rights remain with the creator of the NFT.</Text>
            <Space/>
            <Text fontSize="16px">Non-Fungible Tokens: Each NFT in the Collection is unique and non-fungible. The purchase or acquisition of an NFT does not confer any ownership rights over the underlying artwork or content.</Text>
            <Space/>
            <Text fontSize="16px">No Guarantees: The Collection is provided "as is" without any warranties, express or implied. The creators of the Collection make no guarantees regarding the value, authenticity, or future performance of the NFTs.</Text>
            <Space/>
            <Text fontSize="16px">Intellectual Property: The creators of the Collection retain all intellectual property rights associated with the artwork or content represented by the NFTs. Reproduction, distribution, or unauthorized use of the artwork or content is strictly prohibited.</Text>
            <Space/>
            <Text fontSize="16px">Risk: Investing in NFTs involves inherent risks, including but not limited to volatility in the market, regulatory uncertainty, and technological vulnerabilities. The purchaser acknowledges and accepts these risks.</Text>
            <Space/>
            <Text fontSize="16px">Compliance: Purchasers are responsible for ensuring compliance with all applicable laws and regulations governing the purchase, sale, and ownership of NFTs in their jurisdiction.</Text>
            <Space/>
            <Text fontSize="16px">No Financial Advice: Nothing in this disclaimer constitutes financial, investment, or legal advice. Purchasers are encouraged to conduct their own research and seek professional advice before participating in the NFT market.</Text>
            <Space/>
            <Text fontSize="16px">Modification: The creators of the Collection reserve the right to modify or update this disclaimer at any time without prior notice. It is the responsibility of the purchaser to review the most current version of the disclaimer.</Text>
            <Space/>
            <Text fontSize="16px">By purchasing or acquiring an NFT from the Bullet Last initial NFT Collection, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions outlined in this disclaimer.</Text>
            <Space/>
            <Text fontSize="16px">you acknowledge that you have read and understood the terms and conditions outlined in this disclaimer, and you confirm that you are aged 18 or older</Text>
       </div>
        </RColumn>
       
        </>
    )
}