import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ConnectButton, RColumn, RRow, SpaceHorizontal } from './comps/otherCOmps';
import logoImg from "./assets/logo.png";

const Header = ({isMobile}) => {
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showHome, setShowHome] = useState(false);
  const [showLitepaper, setShowLitepaper] = useState(false);
  const [showBLT, setShowBLT] = useState(false);
  const [showSocial, setShowSocial] = useState(false);


  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".custom-dropdown") &&
      !event.target.closest(".connect-button")
    ) {
      closeAll();
    }
  };
  useState(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  },[]);
  function openSubMenu(tip, val){
    closeAll();
    tip(val);
  }

  function closeAll(){
    setShowAboutUs(false);
    setShowHome(false);
    setShowLitepaper(false);
    setShowBLT(false);
    setShowSocial(false);
  }
  function Buttons(){
    return(
        <>
          <Dropdown className="custom-dropdown" show={showHome} onMouseEnter={() => setShowHome(true)} onMouseLeave={() => setShowHome(false)}>
        <Dropdown.Item variant="transparent" id="dropdown-basic" href='https://bulletlast.games'>
          Home
        </Dropdown.Item>
  
      </Dropdown>
        <Dropdown className="custom-dropdown" show={showAboutUs} onMouseEnter={() => setShowAboutUs(true)} onMouseLeave={() => setShowAboutUs(false)}>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          About us
        </Dropdown.Toggle>
    
        <Dropdown.Menu style={{ backgroundColor: 'black' }}>
          <Dropdown.Item className='drop-menu' href="https://qqventure.capital/">QQ VC</Dropdown.Item>
          <Dropdown.Item className='drop-menu'  href="https://crabsgames.com/">Crabs Games</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    
      <Dropdown className="custom-dropdown" show={showLitepaper} onMouseEnter={() => setShowLitepaper(true)} onMouseLeave={() => setShowLitepaper(false)}>
        <Dropdown.Item variant="transparent" id="dropdown-basic" href='https://bulletlast.games/wp-content/uploads/2024/03/Bullet-Last-Whitepaper-EDITION-9.pdf'>
          Litepaper
        </Dropdown.Item>
  
      </Dropdown>
    
      <Dropdown className="custom-dropdown" show={showBLT} onMouseEnter={() => setShowBLT(true)} onMouseLeave={() => setShowBLT(false)}>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          BLT
        </Dropdown.Toggle>
    
        <Dropdown.Menu style={{ backgroundColor: 'black' }}>
        <Dropdown.Item className='drop-menu'  href="https://polygonscan.com/address/0x25b48D1176d720D905B7014B1F32c91596D5f692#code">Smart Contract</Dropdown.Item>
          <Dropdown.Item className='drop-menu'  href="https://gopluslabs.io/token-security/137/0x25b48D1176d720D905B7014B1F32c91596D5f692">Audit</Dropdown.Item>
          <Dropdown.Item className='drop-menu'  href="#buy">Buy</Dropdown.Item>
          <Dropdown.Item className='drop-menu'  href="https://alpha.bulletlast.games/">Game</Dropdown.Item>
          <Dropdown.Item className='drop-menu'  href="https://bulletlast.games/blog/">Blog</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    
      <Dropdown className="custom-dropdown" show={showSocial} onMouseEnter={() => setShowSocial(true)} onMouseLeave={() => setShowSocial(false)}>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          Social
        </Dropdown.Toggle>
    
        <Dropdown.Menu style={{ backgroundColor: 'black' }}>
          <Dropdown.Item className='drop-menu'  href="https://twitter.com/bullet_last_blt">X</Dropdown.Item>
          <Dropdown.Item className='drop-menu'  href="https://www.youtube.com/@bulletlast_blt">YouTube</Dropdown.Item>
          <Dropdown.Item className='drop-menu'  href="https://discord.gg/WUfx3Radw9">Discord</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      </>
    )
  }

  function ButtonsMobile(){
    return(
      <>
      <RRow>
      <Dropdown className="custom-dropdown" show={showHome} onMouseEnter={() => openSubMenu(setShowHome,true)} onMouseLeave={() => openSubMenu(setShowHome,false)}>
    <Dropdown.Item variant="transparent" id="dropdown-basic" href='https://bulletlast.games'>
      Home
    </Dropdown.Item>

  </Dropdown>
    <Dropdown className="custom-dropdown" show={showAboutUs} onMouseEnter={() => openSubMenu(setShowAboutUs,true)} onMouseLeave={() => openSubMenu(setShowAboutUs,false)}>
    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
      About us
    </Dropdown.Toggle>

    <Dropdown.Menu style={{ backgroundColor: 'black' }}>
      <Dropdown.Item className='drop-menu' href="https://qqventure.capital/">QQ VC</Dropdown.Item>
      <Dropdown.Item className='drop-menu'  href="https://crabsgames.com/">Crabs Games</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>

  <Dropdown className="custom-dropdown" show={showLitepaper} onMouseEnter={() => openSubMenu(setShowLitepaper,true)} onMouseLeave={() => openSubMenu(setShowLitepaper,false)}>
    <Dropdown.Item variant="transparent" id="dropdown-basic" href='https://bulletlast.games/wp-content/uploads/2024/03/Bullet-Last-Whitepaper-EDITION-9.pdf'>
      Litepaper
    </Dropdown.Item>

  </Dropdown>
      </RRow>
      <RRow>
  <Dropdown className="custom-dropdown" show={showBLT} onMouseEnter={() => openSubMenu(setShowBLT,true)} onMouseLeave={() => openSubMenu(setShowBLT,false)}>
    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
      BLT
    </Dropdown.Toggle>

    <Dropdown.Menu style={{ backgroundColor: 'black' }}>
    <Dropdown.Item className='drop-menu'  href="https://polygonscan.com/address/0x25b48D1176d720D905B7014B1F32c91596D5f692#code">Smart Contract</Dropdown.Item>
      <Dropdown.Item className='drop-menu'  href="https://gopluslabs.io/token-security/137/0x25b48D1176d720D905B7014B1F32c91596D5f692">Audit</Dropdown.Item>
      <Dropdown.Item className='drop-menu'  href="#buy">Buy</Dropdown.Item>
      <Dropdown.Item className='drop-menu'  href="https://alpha.bulletlast.games/">Game</Dropdown.Item>
      <Dropdown.Item className='drop-menu'  href="https://bulletlast.games/blog/">Blog</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>

  <Dropdown className="custom-dropdown" show={showSocial} onMouseEnter={() => openSubMenu(setShowSocial,true)} onMouseLeave={() => openSubMenu(setShowSocial,false)}>
    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
      Social
    </Dropdown.Toggle>

    <Dropdown.Menu style={{ backgroundColor: 'black' }}>
      <Dropdown.Item className='drop-menu'  href="https://twitter.com/bullet_last_blt">X</Dropdown.Item>
      <Dropdown.Item className='drop-menu'  href="https://www.youtube.com/@bulletlast_blt">YouTube</Dropdown.Item>
      <Dropdown.Item className='drop-menu'  href="https://discord.gg/WUfx3Radw9">Discord</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
  </RRow>
  </>
    )
  }
  return (
    <div>
    <RRow horizontalAlign='space-between' padding={"12px 0px"}>
        <img src={logoImg} height={"80px"} style={{marginLeft: "30px"}}/>
    <RRow>
    
    {!isMobile && <Buttons/>}
  
    <SpaceHorizontal/>
    <ConnectButton/>
    <SpaceHorizontal/>
  </RRow>
  </RRow>
    <RColumn horizontalAlign='center'>
  {isMobile && <ButtonsMobile/>}
  </RColumn>
  </div>
  );
};

export default Header;
