import { useWeb3ModalProvider, useWeb3ModalAccount, useWeb3Modal, useWeb3ModalEvents } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import { toast } from 'react-toastify';
import { nftAbi } from './collectionAbi';
import { priceNfts } from './App';
import { SecondaryBtn } from './comps/otherCOmps';

const collectionAddress = "0xC5CBAF779E077cEa15F41FD9C7Ee8eCC564f42eb";
const prices = [
    12000000000000000n,
    14000000000000000n,
    16000000000000000n,
    18000000000000000n,
    20000000000000000n,
    22000000000000000n
];
const maxSupply = [3000, 2500, 2000, 1500, 1000, 10];

export function MintBtn({id}){
    const { isConnected, address } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    async function mint(){

        if(!isConnected){
            toast.error("Connect wallet first!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
            });
            return;
        }
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner()
        const balance = await signer.provider.getBalance(address);
        if(balance < prices[id]){
            toast.error("not enough ETH!", {
                position: "top-right",
                autoClose: 3000, 
                hideProgressBar: false,
            });
            return;
        }
        const NftContract = new Contract(collectionAddress, nftAbi, signer);
        try{
            await NftContract.mint(id,1, {value: prices[id]});
            toast.success("NFT minted! Check your wallet", {
                position: "top-right",
                autoClose: 3000, 
                hideProgressBar: false,
            });
            //window.gtag('event', 'conversion', { 'send_to': 'AW-16498453770/qEQGCJ_bxZ0ZEIraibs9', 'transaction_id': address });
        }catch(e){
            toast.error("Problem with transaction", {
                position: "top-right",
                autoClose: 3000, 
                hideProgressBar: false,
            });
            return;
        }
    }
    if(!isConnected) return <></>

    return <SecondaryBtn onClick={() => mint(id)}>Mint<br/>{priceNfts[id]} ETH</SecondaryBtn>

}
export async function getSupplyOfCategory(walletProvider, index){
    
    const ethersProvider = new BrowserProvider(walletProvider);
    const netwotkId = Number.parseInt((await ethersProvider.getNetwork()).chainId);
    if(netwotkId !== 1) return;
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const contract = new Contract(collectionAddress, nftAbi, signer)
    const supply = await contract.supply(index);
    return maxSupply[index] - Number(supply);
}